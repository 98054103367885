<div class="container">

  <div class="row align-items-center justify-content-center" style="height:100vh;">
    <div>

      <div class="row">
        <div class="col-12">
          <a href="javascript:void(0)" (click)="onLoginClick()">
            Loading..
          </a>
        </div>
      </div>

      <br>

    </div>
  </div>

</div>
