<div class="messages">
  <ngb-alert *ngIf="alert.type && alert.type !== ''" [type]="alert.type">
    {{ alert.message }}
  </ngb-alert>
</div>

<p>
  Add Referential Data
</p>
<form [formGroup]="form" class="row form-group" (ngSubmit)="onSubmit(btn)">

  <div class="col-6">
    <label>List Name <span class="text-danger">*</span></label>

    <select class="custom-select" (change)="changeList($event)" formControlName="list_name">
      <option *ngFor="let name of list_names" [ngValue]="name">{{name}}</option>
    </select>

    <small class="d-block">Select the list you want to edit</small>


    <label>Parent <i>( Governorate / District )</i></label>

    <select class="custom-select" (change)="updateCF($event)" formControlName="cf">
      <option *ngFor="let item of parent" [ngValue]="item">{{item}}</option>
    </select>
    <small class="d-block">List item parent</small>


    <label>Label <span class="text-danger">*</span></label>
    <input class="form-control form-control-lg" formControlName="label" placeholder="Label">
    <small class="d-block">List item</small>



    <button #btn type="submit" class="btn btn-dark mt-5 d-block">Add List Item</button>

    <button (click)="generateList()" class="btn btn-outline-primary mt-5 d-block">Generate List</button>

  </div>
</form>
