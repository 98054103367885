
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { LoginComponent } from './pages/login/login.component';

import { AuthGuard } from './auth.guard';
import {AttendanceComponent} from "./pages/attendance/attendance.component";
import {NationalIdComponent} from "./pages/national-id/national-id.component";
import {LogoutComponent} from "./pages/logout/logout.component";
import {ListComponent} from "./pages/list/list.component";
import {QueryComponent} from './pages/query/query.component';

const routes: Routes = [
  { path: 'list', component: ListComponent, canActivate: [AuthGuard] },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent},
  { path: 'logout', component: LogoutComponent, canActivate: [AuthGuard]},
  { path: 'attendance', component: AttendanceComponent, canActivate: [AuthGuard]},
  { path: 'national-id', component: NationalIdComponent, canActivate: [AuthGuard]},
  { path: 'queries', component: QueryComponent, canActivate: [AuthGuard]},
  { path: '', component: LoginComponent },
  { path: '**', redirectTo: '/dashboard' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
