<div class="card-body pb-5">


<div class="row">
      <div class="col-lg-12">
        <div class="quick_access content_main"><h1 class="font-weight-bold">Quick Access</h1>
          <div class="row row-cols-md-2 row-cols-lg-3 widgets">
            <div class="col widget">
              <a routerLink="/attendance" class="border text-center p-5 mt-4 border-dark d-block">
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="graduation-cap" role="img"
                   xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"
                   class="fa-8x svg-inline--fa fa-graduation-cap fa-w-20">
                <path fill="currentColor"
                      d="M622.34 153.2L343.4 67.5c-15.2-4.67-31.6-4.67-46.79 0L17.66 153.2c-23.54 7.23-23.54 38.36 0 45.59l48.63 14.94c-10.67 13.19-17.23 29.28-17.88 46.9C38.78 266.15 32 276.11 32 288c0 10.78 5.68 19.85 13.86 25.65L20.33 428.53C18.11 438.52 25.71 448 35.94 448h56.11c10.24 0 17.84-9.48 15.62-19.47L82.14 313.65C90.32 307.85 96 298.78 96 288c0-11.57-6.47-21.25-15.66-26.87.76-15.02 8.44-28.3 20.69-36.72L296.6 284.5c9.06 2.78 26.44 6.25 46.79 0l278.95-85.7c23.55-7.24 23.55-38.36 0-45.6zM352.79 315.09c-28.53 8.76-52.84 3.92-65.59 0l-145.02-44.55L128 384c0 35.35 85.96 64 192 64s192-28.65 192-64l-14.18-113.47-145.03 44.56z"
                      class=""></path>
              </svg>
                <h2>Training Form</h2>
            </a>
            </div>
            <div class="col widget">
              <a target="_blank" href="https://odk.erasmel.abtassociates.com/Aggregate.html#management/forms///" class="border text-center p-5 mt-4 border-dark d-block">
              <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="wpforms" role="img"
                   xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
                   class="fa-8x svg-inline--fa fa-wpforms fa-w-14">
                <path fill="currentColor"
                      d="M448 75.2v361.7c0 24.3-19 43.2-43.2 43.2H43.2C19.3 480 0 461.4 0 436.8V75.2C0 51.1 18.8 32 43.2 32h361.7c24 0 43.1 18.8 43.1 43.2zm-37.3 361.6V75.2c0-3-2.6-5.8-5.8-5.8h-9.3L285.3 144 224 94.1 162.8 144 52.5 69.3h-9.3c-3.2 0-5.8 2.8-5.8 5.8v361.7c0 3 2.6 5.8 5.8 5.8h361.7c3.2.1 5.8-2.7 5.8-5.8zM150.2 186v37H76.7v-37h73.5zm0 74.4v37.3H76.7v-37.3h73.5zm11.1-147.3l54-43.7H96.8l64.5 43.7zm210 72.9v37h-196v-37h196zm0 74.4v37.3h-196v-37.3h196zm-84.6-147.3l64.5-43.7H232.8l53.9 43.7zM371.3 335v37.3h-99.4V335h99.4z"
                      class=""></path>
              </svg>
              <h2>Forms</h2>
              </a>

            </div>
            <div class="col widget">
              <a routerLink="/queries" class="border text-center p-5 mt-4 border-dark d-block">
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chart-bar" role="img"
                   xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                   class="fa-8x svg-inline--fa fa-chart-bar fa-w-16">
                <path fill="currentColor"
                      d="M332.8 320h38.4c6.4 0 12.8-6.4 12.8-12.8V172.8c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v134.4c0 6.4 6.4 12.8 12.8 12.8zm96 0h38.4c6.4 0 12.8-6.4 12.8-12.8V76.8c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v230.4c0 6.4 6.4 12.8 12.8 12.8zm-288 0h38.4c6.4 0 12.8-6.4 12.8-12.8v-70.4c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v70.4c0 6.4 6.4 12.8 12.8 12.8zm96 0h38.4c6.4 0 12.8-6.4 12.8-12.8V108.8c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v198.4c0 6.4 6.4 12.8 12.8 12.8zM496 384H64V80c0-8.84-7.16-16-16-16H16C7.16 64 0 71.16 0 80v336c0 17.67 14.33 32 32 32h464c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z"
                      class=""></path>
              </svg>
              <h2>Reporting</h2></a>
            </div>
            <div class="col widget mt-5">
              <a href="https://db.erasmel.abtassociates.com/adminer.php?server=eras-main-instance-1.cuynxtfudbni.us-east-1.rds.amazonaws.com" class="border text-center p-5 mt-4 border-dark d-block">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="table" class="svg-inline--fa fa-table fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M464 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zM224 416H64v-96h160v96zm0-160H64v-96h160v96zm224 160H288v-96h160v96zm0-160H288v-96h160v96z"></path></svg>
                <h2>Edit Data</h2></a>
            </div>

            <div class="col widget mt-5">
              <a routerLink="/list" class="border text-center p-5 mt-4 border-dark d-block">
                <i class="fas fa-list mb-1" style="font-size:5em"></i>
                <h2>Additional Values</h2></a>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
