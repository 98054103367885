import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ApiService} from "../../services/api.service";

@Component({
  selector: 'app-attendance-form',
  templateUrl: './attendance-form.component.html',
  styleUrls: ['./attendance-form.component.scss']
})
export class AttendanceFormComponent implements OnInit {
  loading = false;
  form: FormGroup;
  isValidatingID = false;
  participantProfileLink = `https://odk.erasmel.abtassociates.com/Aggregate.html#management/forms///`;
  alert = {
    type: '',
    message: ''
  };

  value_chain = [];
  components = [];
  irr = [];
   nutritionalTrainings = [{
    label: 'non-professional',
    name: 1
  },{
    label: 'professional',
    name: 2
  }];

  constructor(
    private ref: ChangeDetectorRef,
    private fb: FormBuilder, private apiService: ApiService
              ) {
    this.createForm();
  }
  ngxLoader = {
    start : ()=>{
      this.loading = true;
    },
    stop : ()=>{
      this.loading = false;
    }
  };//30202172502934
   onSubmit(btn) {
     this.alert.type = '';
     if(!this.form.get('attendeeArray').valid){
       this.alert.type = 'danger';
       this.alert.message = 'Attendee is invalid';
       return false;
     }
     if(!this.form.valid){
      this.alert.type = 'danger';
      this.alert.message = 'Form is not valid';
      return false;
    }

     btn.disabled = true;
    const form = this.form.value;
    const attendees = form.attendeeArray;
    const promises = [];
     this.ngxLoader.start(); // start master loader
    attendees.forEach(async (attendee)=>{
      const submit  = {...attendee, ...form};
      delete submit.attendeeArray;
      try {
        const promise = this.apiService.createAttendee(submit);
        promises.push(promise);
      } catch(e){
        this.ngxLoader.stop();
        console.error(e);
      }
    });
     Promise.all(promises).then(()=>{
       this.alert.type = 'success';
       this.alert.message = 'Attendee saved';
       this.ngxLoader.stop();
       this.form.disable();
     }).catch((e)=>{
       this.alert.type = 'danger';
       this.alert.message = 'Form did not save. Something went wrong.';
       btn.disabled = false;
       this.ngxLoader.stop();
       this.form.enable();
     })
  }
   newAttendee(){
     return {
       post_test_score: [''],
       pre_test_score: [''],
       number_of_days: ['', Validators.required],
       phone: new FormControl('', {
         validators: [Validators.required, Validators.minLength(11), Validators.pattern("^[0-9]*$")]
       }),
       NATIONAL_ID: new FormControl('', {
         validators: [Validators.required, Validators.minLength(14), Validators.pattern("^[0-9]*$")],
         asyncValidators: this.checkNationalId.bind(this)
       }),
       organization: ['', Validators.required],
       full_name: ['', Validators.required],
     };
   }
   createForm() {
    this.form = this.fb.group({
      from: ['', Validators.required],
      to: ['', Validators.required],
      venue: ['', Validators.required],
      trainers_name: ['', Validators.required],
      training_subject: ['', Validators.required],
      project: ['', Validators.required],
      coordinators_name: ['', Validators.required],
      value_chain: ['', Validators.required],
      irr: ['', Validators.required],
      attendeeArray: this.fb.array([
        this.fb.group(this.newAttendee())
      ])
    });
     this.form.valueChanges
       .subscribe(values => {
         if(values['irr'] === 'IR4'){
           if(!this.form.get('NutritionalTraining')) {
             this.form.addControl('NutritionalTraining', new FormControl(''));
             this.updateNutritionalTrainings(this.nutritionalTrainings[0].name);
           }
         } else {
           if(this.form.get('NutritionalTraining')) {
             this.form.removeControl('NutritionalTraining');
           }
         }
       });

  }



  async checkNationalId({value}: AbstractControl) {


    if(value.toString().length !== 14){
      return null;
    }

    try {
      this.isValidatingID = true;
      this.ngxLoader.start();
      const {response} = await this.apiService.checkNationalId(value);
      this.ngxLoader.stop();
      const [ participants, attendees ] = response;
      this.isValidatingID = false;

      if (participants.C === 0) {
        return {
         participant_missing: true
        };
      }

      return null
    }catch(e){
      this.isValidatingID = true;
      this.ngxLoader.stop();
      return null;
    }
  }




  updateNutritionalTrainings(val){
    this.form.get('NutritionalTraining').setValue( val)
  }
  updateComponent(val){
    this.form.get('project').setValue( val)
  }
   updateValueChain(val){
    this.form.get('value_chain').setValue(val)
  }
   updateIRR(val){
    this.form.get('irr').setValue(val)
  }

  get attendeeArray(): FormArray {
    return this.form.get('attendeeArray') as FormArray;
  }
  removeAttendee(i){
    this.attendeeArray.removeAt(i)
  }
  addAttendee(event) {

    this.alert.type = '';
    event.preventDefault();
    const last = this.attendeeArray.controls[this.attendeeArray.controls.length - 1];
    if(last && last.valid === false){
      this.alert.type = 'danger';
      this.alert.message = 'Attendee is invalid. Fix the current attendee before adding a new one.';
      return false;
    }
    this.attendeeArray.push(this.fb.group(this.newAttendee()));
    this.ref.detectChanges();
  }

  async ngOnInit() {
    this.ngxLoader.start();
    const value_chain = await this.apiService.getList('value_chain');
    this.value_chain = value_chain.response;
    this.updateValueChain(this.value_chain[0].name);
    const component = await this.apiService.getList('component');
    this.components = component.response;
    this.updateComponent(this.components[0].name);
    const irr = await this.apiService.getList('irr');
    this.irr = irr.response;
    this.updateIRR(this.irr[0].name);
    if(window.location.href.indexOf('localhost') > -1) {

    }
    this.ngxLoader.stop();
  }
}
