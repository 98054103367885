<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>



<div class="messages">
  <ngb-alert *ngIf="alert.type && alert.type !== ''" [type]="alert.type" [dismissible]="false">
    {{ alert.message }}
  </ngb-alert>
</div>

<form [formGroup]="form" class="row form-group" (ngSubmit)="onSubmit(btn)">

  <div class="col-6">
    <label>From <span class="text-danger">*</span></label>
    <input
      class="form-control form-control-sm"
      formControlName="from"
      [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" placeholder="From">
    <owl-date-time #dt1></owl-date-time>

    <small
      class="text-danger"
      *ngIf="(form.controls.from.dirty || form.controls.from.touched) && form.controls.from.invalid && form.controls.from.errors.required"
    >
      <i class="fas fa-exclamation-circle"></i> Required
    </small>

  </div>

  <div class="col-6">

    <label>To <span class="text-danger">*</span></label>

    <input
      class="form-control form-control-sm"
      formControlName="to"
      [owlDateTime]="dt2" [owlDateTimeTrigger]="dt2" placeholder="To">
    <owl-date-time #dt2></owl-date-time>

    <small
      class="text-danger"
      *ngIf="(form.controls.to.dirty || form.controls.to.touched) && form.controls.to.invalid && form.controls.to.errors.required"
    >
      <i class="fas fa-exclamation-circle"></i> Required
    </small>


  </div>


  <div class="col-4">
    <label>Venue <span class="text-danger">*</span></label>
    <input class="form-control form-control-sm" formControlName="venue" placeholder="Venue">

    <small
      class="text-danger"
      *ngIf="(form.controls.venue.dirty || form.controls.venue.touched) && form.controls.venue.invalid && form.controls.venue.errors.required"
    >
      <i class="fas fa-exclamation-circle"></i> Required
    </small>


  </div>
  <div class="col-4">
    <label>Trainers Name <span class="text-danger">*</span>

    </label>

    <input class="form-control form-control-sm" formControlName="trainers_name" placeholder="Trainers Name">

    <small
      class="text-danger"
      *ngIf="(form.controls.trainers_name.dirty || form.controls.trainers_name.touched) && form.controls.trainers_name.invalid && form.controls.trainers_name.errors.required"
    >
      <i class="fas fa-exclamation-circle"></i> Required
    </small>


  </div>
  <div class="col-4">
    <label>Training Subject <span class="text-danger">*</span></label>

    <input class="form-control form-control-sm" formControlName="training_subject" placeholder="Training Subject">

    <small
      class="text-danger"
      *ngIf="(form.controls.training_subject.dirty || form.controls.training_subject.touched) && form.controls.training_subject.invalid && form.controls.training_subject.errors.required"
    >
      <i class="fas fa-exclamation-circle"></i> Required
    </small>
  </div>
  <!---
  -	Intermediate Result (IR)
  -->
  <div class="col-6">
    <label>Component <span class="text-danger">*</span></label>
    <select class="form-control" (change)="updateComponent($event.target.value)" formControlName="project">
      <option *ngFor="let val of components" value={{val.name}}>
        {{val.label}}
      </option>
    </select>
    <small
      class="text-danger"
      *ngIf="(form.controls.project.dirty || form.controls.project.touched) && form.controls.project.invalid && form.controls.project.errors.required"
    >
      <i class="fas fa-exclamation-circle"></i> Required
    </small>
  </div>

  <div class="col-6">
    <label>Intermediate Result (IR)  <span class="text-danger">*</span></label>
    <select class="form-control" (change)="updateIRR($event.target.value)" formControlName="irr">
      <option *ngFor="let val of irr" value={{val.name}}>
        {{val.label}}
      </option>
    </select>
    <div class="row" *ngIf="form.get('NutritionalTraining')">
<div class="col-12">
  <label>Nutrition-related training <span class="text-danger">*</span></label>
  <select class="form-control" (change)="updateNutritionalTrainings($event.target.value)" formControlName="NutritionalTraining">
    <option *ngFor="let val of nutritionalTrainings" value={{val.name}}>
      {{val.label}}
    </option>
  </select>
</div>


    </div>
  </div>

  <div class="col-6">
    <label>Coordinators Name <span class="text-danger">*</span></label>
    <input class="form-control form-control-sm" formControlName="coordinators_name" placeholder="Coordinators Name">

    <small
      class="text-danger"
      *ngIf="(form.controls.coordinators_name.dirty || form.controls.coordinators_name.touched) && form.controls.coordinators_name.invalid && form.controls.coordinators_name.errors.required"
    >
      <i class="fas fa-exclamation-circle"></i> Required
    </small>


  </div>

  <div class="col-4">
    <label>Value Chain<span class="text-danger">*</span></label>
    <select class="form-control" (change)="updateValueChain($event.target.value)" formControlName="value_chain">
      <option *ngFor="let val of value_chain" value={{val.name}}>
        {{val.label}}
      </option>
    </select>
  </div>


  <div class="col-12 mt-2 mb-2">
    <button

      class="btn btn-dark btn-sm" (click)="addAttendee($event)" role="button">Add Attendee</button>

  </div>


  <div formArrayName="attendeeArray" class="m-3">
    <div *ngFor="let att of attendeeArray.controls; let i = index" [formGroupName]="i"
         class="row form-group attendee p-3">
      <div class="col-12 text-right">
        <button class="btn btn-danger btn-sm" (click)="removeAttendee(i)" role="button">Remove</button>
      </div>
      <div class="col-4">
        <label class="d-block">National ID <span class="text-danger">*</span>

          <small class="float-right font-weight-normal"
                 [ngClass]="{'text-success': att.get('NATIONAL_ID').value.length === 14,
                 'text-warning': att.get('NATIONAL_ID').value.length < 14}"
                 *ngIf="att.get('NATIONAL_ID').value">
            char {{att.get('NATIONAL_ID').value.toString().length}}
          </small>

        </label>


        <input
          type="string"
          minlength="14"
          maxlength="14"
          class="form-control form-control-sm d-block"
          formControlName="NATIONAL_ID" placeholder="National ID">
        <i class="fas validating" [ngClass]="{'fa-spin fa-spinner': isValidatingID}"></i>

        <div class="d-block">
          <small class="text-danger" *ngIf="att.get('NATIONAL_ID').hasError('wrongNumber')"><i
            class="fas fa-exclamation-circle"></i> National ID needs to start with 2 or 3<br></small>
          <small class="text-danger" *ngIf="att.get('NATIONAL_ID').hasError('participant_missing')">

            <i class="fas fa-exclamation-circle"></i> National ID does not exist! <a target="_blank"
                                                                                     [href]="participantProfileLink">Click
            Here</a><br></small>
          <small class="text-danger" *ngIf="att.get('NATIONAL_ID').hasError('attendee_exists')"><i
            class="fas fa-exclamation-circle"></i> Attendee already exists in the database! <br></small>

          <small class="text-danger"
                 *ngIf="att.get('NATIONAL_ID').touched && att.get('NATIONAL_ID').hasError('required')"><i
            class="fas fa-exclamation-circle"></i> Required <br></small>
          <small class="text-danger"
                 *ngIf="att.get('NATIONAL_ID').touched && att.get('NATIONAL_ID').hasError('minlength')"><i
            class="fas fa-exclamation-circle"></i> Min Length 14 <br></small>
          <small class="text-danger"
                 *ngIf="att.get('NATIONAL_ID').touched && att.get('NATIONAL_ID').hasError('pattern')"><i
            class="fas fa-exclamation-circle"></i> Numbers Only <br></small>


        </div>

      </div>
      <div class="col-4">
        <label>Post Test Score </label>

        <input
          type="number"
          class="form-control form-control-sm"
          formControlName="post_test_score" placeholder="Post Test Score">


      </div>
      <div class="col-4">
        <label>Pre Test Score </label>
        <input
          type="number"
          class="form-control form-control-sm"
          formControlName="pre_test_score" placeholder="Pre Test Score">



      </div>

      <div class="col-3">
        <label>Number of Training Days <span class="text-danger">*</span></label>
        <input
          type="number"
          class="form-control form-control-sm"
          formControlName="number_of_days"
          placeholder="Training Days">

        <small class="text-danger"
               *ngIf="att.get('number_of_days').touched && att.get('number_of_days').hasError('required')">

          <i class="fas fa-exclamation-circle"></i> Required</small>

      </div>
      <div class="col-3">


      </div>

      <div class="col-3">

      </div>


      <div
        class="col-3">


      </div>
      <div class="col-4">
        <label class="d-block">Telephone<span class="text-danger">*</span>
          <small class="font-weight-normal float-right"
                 [ngClass]="{'text-success': att.get('phone').value.length === 11, 'text-warning': att.get('phone').value.length < 11}"
          >char {{att.get('phone').value.toString().length}}</small>
        </label>

        <input
          maxlength="11"
          minlength="11"
          type="text"
          class="form-control form-control-sm"
          formControlName="phone" placeholder="Phone">

        <small class="text-danger" *ngIf="att.get('phone').touched && att.get('phone').hasError('required')"><i
          class="fas fa-exclamation-circle"></i> Required <br></small>
        <small class="text-danger" *ngIf="att.get('phone').touched && att.get('phone').hasError('minlength')"><i
          class="fas fa-exclamation-circle"></i> Min Length 11 <br></small>
        <small class="text-danger" *ngIf="att.get('phone').touched && att.get('phone').hasError('pattern')"><i
          class="fas fa-exclamation-circle"></i> Numbers Only <br></small>


      </div>
      <div class="col-4">
        <label>Organization <span class="text-danger">*</span></label>

        <input
          class="form-control form-control-sm"
          formControlName="organization" placeholder="Organization">
        <small class="text-danger"
               *ngIf="att.get('organization').touched && att.get('organization').hasError('required')"><i
          class="fas fa-exclamation-circle"></i> Required</small>


      </div>
      <div class="col-4">
        <label>Full Name <span class="text-danger">*</span></label>

        <input
          class="form-control form-control-sm"
          formControlName="full_name" placeholder="Full Name">

        <small class="text-danger" *ngIf="att.get('full_name').touched && att.get('full_name').hasError('required')"><i
          class="fas fa-exclamation-circle"></i> Required</small>


      </div>


    </div>
  </div>

  <div class="col-12 mt-2 mb-2">

    <button #btn type="submit" class="btn btn-primary">Submit</button>
  </div>

</form>
