import { Injectable } from '@angular/core';
import API, { graphqlOperation } from '@aws-amplify/api';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  constructor() {
  }
}
