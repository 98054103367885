



<div class="card-header">
  <h1>
    Additional Values
  </h1>
</div>
<div class="card-body p-3">


  <div class="row">
    <div class="col">

      <div class="mt-3">
        <app-list-form></app-list-form>
        <a href="javascript:void(0)" (click)="downloadS3File()">Download National ID's</a>
      </div>

    </div>
  </div>
</div>
