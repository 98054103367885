<nav class="navbar py-4 navbar-dark bg-dark  navbar-expand-lg">
  <a href="/" class="navbar-brand nuxt-link-exact-active nuxt-link-active">ERAS</a>
  <button type="button" aria-label="Toggle navigation" aria-controls="nav-collapse" aria-expanded="false"
          class="navbar-toggler"><span class="navbar-toggler-icon"></span></button>


  <div id="nav-collapse" class="navbar-collapse collapse" style="display:none;">

    <ul class="navbar-nav ml-auto">
      <li>
        <a href="/logout" class="nav-link">Logout</a>

      </li>

    </ul>
  </div>
</nav>
