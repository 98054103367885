import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import Amplify from 'aws-amplify';

//import awsconfig from './aws-exports';

const awsconfig = {
  "aws_project_region": "us-east-1",
  "aws_cognito_identity_pool_id": "us-east-1:49226049-2738-4939-be79-a0c0eeaf50a0",
  "aws_cognito_region": "us-east-1",
  "aws_user_pools_id": "us-east-1_IGHpQWFou",
  "aws_user_pools_web_client_id": "7puka729idfdhec0t5umcq74ml",
  "oauth": {
    "domain": "auth.erasmel.abtassociates.com",
    "scope": [
      "email",
      "openid",
      "aws.cognito.signin.user.admin"
    ],
    "redirectSignIn": "https://app.erasmel.abtassociates.com",
    "redirectSignOut": "https://app.erasmel.abtassociates.com/logout",
    "responseType": "code"
  },
  "aws_cloud_logic_custom": [
    {
      "name": "api",
      "endpoint": "https://5kaqu973fb.execute-api.us-east-1.amazonaws.com/prod",
      "region": "us-east-1"
    }
  ],
  Storage: {
    AWSS3: {
      bucket: 'eras-national-id-out', //REQUIRED -  Amazon S3 bucket name
      region: 'us-east-1', //OPTIONAL -  Amazon service region
    }
  }
};



if(window.location.href.indexOf('localhost') > -1){
  awsconfig.oauth.redirectSignIn = 'http://localhost:4200';
  awsconfig.oauth.redirectSignOut = 'http://localhost:4200/logout';
  awsconfig.aws_cloud_logic_custom[0].endpoint = 'https://5kaqu973fb.execute-api.us-east-1.amazonaws.com/prod';
}

if(window.location.href.indexOf('master') > -1){
  awsconfig.oauth.redirectSignIn = 'https://master.due6tcvjw717u.amplifyapp.com';
  awsconfig.oauth.redirectSignOut = 'https://master.due6tcvjw717u.amplifyapp.com/logout';
}



Amplify.configure(awsconfig);


if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
