import { Injectable } from '@angular/core';
import API from "@aws-amplify/api";
@Injectable({
  providedIn: 'root'
})
export class ApiService {


  constructor() {

  }
  async getDistinct(field){
    const apiName = 'api';
    const path = `/api/list/autocomplete`;
    const myInit = {
      'queryStringParameters': {
          'field': field
        }
    };
        return await API.get(apiName, path, myInit);
  }
  async createAttendee(attendee) {
    const apiName = 'api';
    const path = '/api/attendee';
    const myInit = {
      headers: {
        'Content-Type': 'application/json'
      }, body: attendee
    };
    return await API.post(apiName, path, myInit);
  }
  async getData() {
    const apiName = 'api';
    const path = '/api';
    const myInit = {
      headers: {}
    };

    return await API.post(apiName, path, myInit);
  }
  async checkNationalId(nationalId){
    const apiName = 'api';
    const path = `/api/national-id/${nationalId}`;
    const myInit = {
    };
    return await API.get(apiName, path, myInit);
  }
  async getList(listName){
    const apiName = 'api';
    const path = `/api/list`;
    const myInit = {
      'queryStringParameters': {
        'list_name': listName
      }
    };
    return await API.get(apiName, path, myInit);
  }
  async addToList(list){
    const apiName = 'api';
    const path = '/api/list';
    const myInit = {
      headers: {
        'Content-Type': 'application/json'
      }, body: list
    };
    return await API.post(apiName, path, myInit);
  }
  async generateList(){
    const apiName = 'api';
    const path = `/api/list/generate`;
    const myInit = {
    };
    return await API.get(apiName, path, myInit);
  }
}
