import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-list-attendance',
  templateUrl: './list-attendance.component.html',
  styleUrls: ['./list-attendance.component.scss']
})
export class ListAttendanceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
