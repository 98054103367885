
<div class="card-header">
  <h1>
    Training Form <span class="float-right">كشف تسجيل حضور التدريب   -</span>
  </h1>
</div>
<div class="card-body p-3">

<div class="row">
  <div class="col">

    <div class="mt-3">
      <app-attendance-form></app-attendance-form>
    </div>

  </div>
</div>
</div>
