import { Component, OnInit, NgZone } from '@angular/core';
import { Auth, Hub } from 'aws-amplify';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import {DashboardService} from "../../services/dashboard.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(
    private dashboardService: DashboardService,
    private router: Router,
    private zone: NgZone,
    private spinner: NgxSpinnerService) {


    Hub.listen("auth", ({ payload: { event, data } }) => {
      console.log(data, event)
      if (event === "cognitoHostedUI" || event === "signedIn") {
        this.zone.run(() => this.router.navigate(['/dashboard']));
      } else {
      }
    });


  }

  async ngOnInit() {
    this.spinner.show();
    setTimeout(()=>{
      Auth.currentAuthenticatedUser()
        .then((user) => {
          this.router.navigate(['/dashboard'], { replaceUrl: true });
        }).catch((err) => {
          console.error(err);
        Auth.federatedSignIn();
        this.spinner.hide();
      })


    },3000);
    return;
  }

  onLoginClick() {
    Auth.federatedSignIn();
  }

}
