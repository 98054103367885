import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import {HttpClientModule} from "@angular/common/http";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import { LoginComponent } from './pages/login/login.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import {AuthGuard} from "./auth.guard";
import { AttendanceFormComponent } from './components/attendance-form/attendance-form.component';
import {AmplifyAngularModule, AmplifyService} from "aws-amplify-angular";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AttendanceComponent } from './pages/attendance/attendance.component';
import { ListAttendanceComponent } from './pages/list-attendance/list-attendance.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { NationalIdComponent } from './pages/national-id/national-id.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { ListComponent } from './pages/list/list.component';
import { ListFormComponent } from './components/list-form/list-form.component';
import { QueryComponent } from './pages/query/query.component';
import { NgxLoadingModule } from 'ngx-loading';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NavBarComponent,
    DashboardComponent,
    AttendanceFormComponent,
    AttendanceComponent,
    ListAttendanceComponent,
    LogoutComponent,
    NationalIdComponent,
    ListComponent,
    ListFormComponent,
    QueryComponent
  ],
  imports: [
    ReactiveFormsModule,
    NgxSpinnerModule,
    HttpClientModule,
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AmplifyAngularModule,
    NgbModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgxLoadingModule.forRoot({})
  ],
  providers: [AuthGuard,AmplifyService],
  bootstrap: [AppComponent]
})
export class AppModule { }
