import { Component, OnInit } from '@angular/core';
import {Auth} from "aws-amplify";
import * as AWS from "aws-sdk";

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

  constructor() { }

  public async downloadS3File(){
    let creds = await Auth.currentCredentials();
    AWS.config.update({
      region: 'us-east-1',
      credentials: creds
    });
    const s3 = new AWS.S3({
      apiVersion: '2006-03-01',
      params: {Bucket: 'eras-national-id-out'}
    });

    try {
      const signedUrlExpireSeconds = 60 * 5;
      const url = s3.getSignedUrl('getObject', {
        Bucket: 'eras-national-id-out',
        Key:'NATIONAL_IDs.csv',
        Expires: signedUrlExpireSeconds
      });
      window.location.href = url;

    }catch(e){
      console.error(e);
    }

  }

  ngOnInit(): void {

  }

}
