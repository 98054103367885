import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ApiService} from "../../services/api.service";
import {Observable} from "rxjs";
import {debounceTime, distinctUntilChanged, map} from "rxjs/operators";

@Component({
  selector: 'app-list-form',
  templateUrl: './list-form.component.html',
  styleUrls: ['./list-form.component.scss']
})
export class ListFormComponent implements OnInit {

  form: FormGroup;
  alert = {
    message : '',
    type: ''
  };

  constructor(private fb: FormBuilder, private apiService: ApiService) {
  }



  public Governorate_list = [];
  public District_list = [];
  public list_names = ['Governorate','District','Village','University', 'Coordinator','value_chain','component', 'irr'];
  public auto_complete_search_list = [];
  public parent = [];


  updateCF(e) {
    this.form.get('cf').setValue(e.target.value, {
      onlySelf: true
    });
  }
  changeList(e){
    this.form.get('list_name').setValue(e.target.value, {
      onlySelf: true
    });

    this.form.get('cf').disable();

    if(e.target.value.indexOf('Coordinator') > -1){

      this.form.get('cf').setValue('');
    }
    if(e.target.value.indexOf('District') > -1){
      this.auto_complete_search_list =  [...this.District_list];
      this.parent = [ ...this.Governorate_list];
      this.form.get('cf').enable();
    }
    if(e.target.value.indexOf('Village') > -1){
      this.parent = this.District_list;
      this.form.get('cf').enable();
    }
    if(e.target.value.indexOf('University') > -1 || e.target.value.indexOf('Governorate') > -1) {
      this.form.get('cf').setValue('');

    }
  }
  async ngOnInit() {

    await this.createForm();

    const list = await this.apiService.getDistinct('list_name');
    const list_1 = list.response;
    this.auto_complete_search_list =  [...list_1['Governorate'], ...list_1['District']];
    this.Governorate_list = list_1['Governorate'];
    this.District_list = list_1['District'];
  }


  auto_complete_search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 ? []
        : this.auto_complete_search_list.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    );


  async createForm() {
    this.form = this.fb.group({
      list_name: ['', Validators.required],
      label: ['', Validators.required],
      cf: ['']
    })
  }
  async onSubmit(btn){
   delete this.alert.message;
    delete this.alert.type;
    if(this.form.invalid){
      return;
    }

    const json = this.form.value;
    const list_name = json.list_name.split(':')[1];
    if(json.cf && json.cf.length > 0) {
      json.cf = json.cf.split(':')[1].trim();
    }

    json.list_name = list_name.trim();
    json.name = json.label.trim();

    await this.apiService.addToList(json);
    this.alert.message = 'List item added';
    this.alert.type = 'success';


  }

  async generateList(){
    delete this.alert.message;
    delete this.alert.type;
    await this.apiService.generateList();
    this.alert.message = 'List created';
    this.alert.type = 'success';
  }

}
