<div class="card-header">
  <h1>
    Reporting
  </h1>
  <p>
    Queries show aggregated values and underlying data.
    Participants are uniquely identified via their National ID. A participant’s National ID may appear multiple times in the underlying data if that participant attended multiple trainings. Aggregate queries uniquely count participants and only count a National ID once to avoid double counting.
  </p>
</div>
<div class="card-body p-3">



  <div class="row">
    <div class="col">

      <div class="mt-3">

        <b class="mt-3 mb-3">EG 3-2: NUMBER OF INDIVIDUALS PARTICIPATING IN USG FOOD SECURITY PROGRAMS</b>
        <ul class="list-group mb-5">
          <li class="list-group-item">
            <a target="_blank" href="https://db.erasmel.abtassociates.com/adminer.php?server=eras-main-instance-1.cuynxtfudbni.us-east-1.rds.amazonaws.com&username=Hamdy_Mansour&db=aggregate&sql=call%20Get_Attendee_Continuous_Unique_Count(%2710-01-2019%27%2C%2709-30-2020%27)%3B%0Acall%20Get_Attendee_Continuous_Unique_Count_Composite(%2710-01-2019%27%2C%2709-30-2020%27)%3B%0A%0A">Get_Attendee_Continuous_Unique_Count FY20</a>
          </li>
          <li class="list-group-item">
            <a target="_blank" href="https://db.erasmel.abtassociates.com/adminer.php?server=eras-main-instance-1.cuynxtfudbni.us-east-1.rds.amazonaws.com&username=Hamdy_Mansour&db=aggregate&sql=call%20Get_Attendee_Age_Category(%2710-01-2019%27%2C%2709-30-2020%27)%3B%0Acall%20Get_Attendee_Age_Category_Composite(%2710-01-2019%27%2C%2709-30-2020%27)%3B%0A%0A">Get_Attendee_Age_Category FY20</a>
          </li>

          <li class="list-group-item">
            <a target="_blank" href="https://db.erasmel.abtassociates.com/adminer.php?server=eras-main-instance-1.cuynxtfudbni.us-east-1.rds.amazonaws.com&username=Hamdy_Mansour&db=aggregate&sql=call%20Get_Attendee_Gender_Count(%2710-01-2019%27%2C%2709-30-2020%27)%3B%0Acall%20Get_Attendee_Gender_Count_Composite(%2710-01-2019%27%2C%2709-30-2020%27)%3B%0A%0A">Get_Attendee_Gender_Count FY20
            </a>
          </li>

          <li class="list-group-item">
            <a target="_blank" href="https://db.erasmel.abtassociates.com/adminer.php?server=eras-main-instance-1.cuynxtfudbni.us-east-1.rds.amazonaws.com&username=Hamdy_Mansour&db=aggregate&sql=CALL%20Get_Farmer_Details(%2710-01-2019%27%2C%2709-30-2020%27)%3B%0ACALL%20Get_Farmer_Details_Composite(%2710-01-2019%27%2C%2709-30-2020%27)%3B%0A%0A">
              Get_Farmer_Details FY20
            </a>
          </li>

          <li class="list-group-item">
            <a target="_blank" href="https://db.erasmel.abtassociates.com/adminer.php?server=eras-main-instance-1.cuynxtfudbni.us-east-1.rds.amazonaws.com&username=Hamdy_Mansour&db=aggregate&sql=CALL%20Number_individuals_in_USG_fsp_EG32_Attendee_Desc(%2710-01-2019%27%2C%2709-30-2020%27)%3B%0ACALL%20Number_indi_in_USG_fsp_EG32_Attendee_Desc_Composite(%2710-01-2019%27%2C%2709-30-2020%27)%3B%0A%0A">
              Number_individuals_in_USG_fsp_EG32_Attendee_Desc FY20
            </a>
          </li>

        </ul>



        <b class="mt-3 mb-3"> HL.9-4: NUMBER OF INDIVIDUALS RECEIVING NUTRITION-RELATED PROFESSIONAL TRAINING THROUGH USG-SUPPORTED PROGRAMS</b>
        <ul class="list-group mb-5">
          <li class="list-group-item">
            <a target="_blank" href="//db.erasmel.abtassociates.com/adminer.php?server=eras-main-instance-1.cuynxtfudbni.us-east-1.rds.amazonaws.com&username=Hamdy_Mansour&db=aggregate&sql=CALL%20Number_individuals_nutrition_prof_HL94_Cont_Unique_Count(%2710-01-2019%27%2C%2709-31-2020%27)%3B%20%0ACALL%20Number_ind_nutrition_prof_HL94_Cont_Unique_Count_Composite(%2710-01-2019%27%2C%2709-31-2020%27)%3B%20%0A">Number_individuals_nutrition_prof_HL94_Cont_Unique_Count FY20 </a>
          </li>
          <li class="list-group-item">
            <a target="_blank" href="https://db.erasmel.abtassociates.com/adminer.php?server=eras-main-instance-1.cuynxtfudbni.us-east-1.rds.amazonaws.com&username=Hamdy_Mansour&db=aggregate&sql=CALL%20Number_individuals_prof_HL94_Cont_Unique_Female_Count(%2710-01-2019%27%2C%2709-31-2020%27)%3B%0ACALL%20Number_ind_prof_HL94_Cont_Unique_Female_Count_Composite(%2709-01-2019%27%2C%2710-31-2020%27)%3B%20%0A">
               Number_individuals_prof_HL94_Cont_Unique_Female_Count FY20
            </a>
          </li>
          <li class="list-group-item">
            <a target="_blank" href="https://db.erasmel.abtassociates.com/adminer.php?server=eras-main-instance-1.cuynxtfudbni.us-east-1.rds.amazonaws.com&username=Hamdy_Mansour&db=aggregate&sql=CALL%20Number_individuals_prof_HL94_Cont_Unique_Male_Count(%2710-01-2019%27%2C%2709-31-2020%27)%3B%20%0ACALL%20Number_ind_prof_HL94_Cont_Unique_Male_Count_Composite(%2709-01-2019%27%2C%2710-31-2020%27)%3B%20%0A">
               Number_individuals_prof_HL94_Cont_Unique_Male_Count FY20
            </a>
          </li>

        </ul>



        <b class="mt-3 mb-3">GNDR-2 – PERCENTAGE OF FEMALE PARTICIPANTS IN USG-ASSISTED PROGRAMS DESIGNED TO INCREASE ACCESS TO PRODUCTIVE ECONOMIC RESOURCES (ASSETS, CREDIT, INCOME, OR EMPLOYMENT)</b>
        <ul class="list-group mb-5">
          <li class="list-group-item">
            <a target="_blank" href="https://db.erasmel.abtassociates.com/adminer.php?server=eras-main-instance-1.cuynxtfudbni.us-east-1.rds.amazonaws.com&username=Hamdy_Mansour&db=aggregate&sql=CALL%20Percent_female_participants_USG_GNDR2_Annual()%3B">Percent_female_participants_USG_GNDR2 FY20</a>

          </li>
        <li class="list-group-item">
            <a target="_blank" href="https://db.erasmel.abtassociates.com/adminer.php?server=eras-main-instance-1.cuynxtfudbni.us-east-1.rds.amazonaws.com&username=Hamdy_Mansour&db=aggregate&sql=CALL%20Percent_female_participants_USG_GNDR2_Num_Composite_Annual()%3B%20%0ACALL%20Percent_female_participants_USG_GNDR2_Den_Composite_Annual()%3B%0A">Percent_female_participants_USG_GNDR2 FY20 Composite</a>

          </li>


          <li class="list-group-item">
            <a target="_blank" href="https://db.erasmel.abtassociates.com/adminer.php?server=eras-main-instance-1.cuynxtfudbni.us-east-1.rds.amazonaws.com&username=Hamdy_Mansour&db=aggregate&sql=CALL%20Percent_female_participants_USG_GNDR2_Num_Composite_Annual()%3B%20%0ACALL%20Percent_female_participants_USG_GNDR2_Den_Composite_Annual()%3B%0A">Percent_female_participants_USG_GNDR2 Annual FY20 Composite</a>

          </li>


          <li class="list-group-item">
            <a target="_blank" href="https://db.erasmel.abtassociates.com/adminer.php?server=eras-main-instance-1.cuynxtfudbni.us-east-1.rds.amazonaws.com&username=Hamdy_Mansour&db=aggregate&sql=CALL%20Percent_female_participants_USG_GNDR2(%2710-01-2019%27%2C%2712-31-2019%27%2C1%2C%202020)%3B%0ACALL%20Percent_female_participants_USG_GNDR2_Num_Composite(%2710-01-2019%27%2C%2712-31-2019%27%2C%201%2C%202020)%3B%0ACALL%20Percent_female_participants_USG_GNDR2_Den_Composite(%2710-01-2019%27%2C%2712-31-2019%27%2C%201%2C%202020)%3B%0A">Percent_female_participants_USG_GNDR2 Quarterly FY20</a>

          </li>
        </ul>




        <b class="mt-3 mb-3 text-uppercase">EG.3.2-7:  Number of technologies, practices, and approaches under various phases of research, development, and uptake as a result of USG assistance</b>
        <ul class="list-group mb-5">
          <li class="list-group-item">
            <a target="_blank" href="https://db.erasmel.abtassociates.com/adminer.php?server=eras-main-instance-1.cuynxtfudbni.us-east-1.rds.amazonaws.com&username=Hamdy_Mansour&db=aggregate&sql=CALL%20Number_new_technologies_EG327_Tech_Count(1%2C2020)%3B%0ACALL%20Number_new_technologies_EG327_Tech_Count_Composite(1%2C2020)%3B%0ACALL%20Number_new_technologies_EG327_Tech_Count(2%2C2020)%3B%0ACALL%20Number_new_technologies_EG327_Tech_Count_Composite(2%2C2020)%3B%0ACALL%20Number_new_technologies_EG327_Tech_Count(3%2C2020)%3B%0ACALL%20Number_new_technologies_EG327_Tech_Count_Composite(3%2C2020)%3B%0ACALL%20Number_new_technologies_EG327_Tech_Count(4%2C2020)%3B%0ACALL%20Number_new_technologies_EG327_Tech_Count_Composite(4%2C2020)%3B%0A%0A"> Number_new_technologies_EG327_Tech_Count FY20</a>
          </li>   <li class="list-group-item">
            <a target="_blank" href="https://db.erasmel.abtassociates.com/adminer.php?server=eras-main-instance-1.cuynxtfudbni.us-east-1.rds.amazonaws.com&username=Hamdy_Mansour&db=aggregate&sql=CALL%20Number_new_technologies_EG327_Tech_Count_DevPhase_PerCat(1%2C2020)%3B%0ACALL%20Number_new_technologies_EG327_Tech_Count_DevPhase_PerCat(2%2C2020)%3B%0ACALL%20Number_new_technologies_EG327_Tech_Count_DevPhase_PerCat(3%2C2020)%3B%0ACALL%20Number_new_technologies_EG327_Tech_Count_DevPhase_PerCat(4%2C2020)%3B%0A"> Number_new_technologies_EG327_Tech_Count_DevPhase_PerCat FY20</a>
        </li>   <li class="list-group-item">
          <a target="_blank" href="https://db.erasmel.abtassociates.com/adminer.php?server=eras-main-instance-1.cuynxtfudbni.us-east-1.rds.amazonaws.com&username=Hamdy_Mansour&db=aggregate&sql=CALL%20Number_new_technologies_EG327_by_DevPhase(1%2C2020)%3B%0ACALL%20Number_new_technologies_EG327_by_DevPhase(2%2C2020)%3B%0ACALL%20Number_new_technologies_EG327_by_DevPhase(3%2C2020)%3B%0ACALL%20Number_new_technologies_EG327_by_DevPhase(4%2C2020)%3B%0A"> Number_new_technologies_EG327_by_DevPhase FY20</a>
        </li>   <li class="list-group-item">
            <a target="_blank" href="https://db.erasmel.abtassociates.com/adminer.php?server=eras-main-instance-1.cuynxtfudbni.us-east-1.rds.amazonaws.com&username=Hamdy_Mansour&db=aggregate&sql=CALL%20Number_new_tech_EG327_Plant_and_Animal_Improvement_Count(1%2C2020)%3B%0ACALL%20Number_new_tech_EG327_Plant_and_Animal_Improvement_Count(2%2C2020)%3B%0ACALL%20Number_new_tech_EG327_Plant_and_Animal_Improvement_Count(3%2C2020)%3B%0ACALL%20Number_new_tech_EG327_Plant_and_Animal_Improvement_Count(4%2C2020)%3B%0A"> Number_new_tech_EG327_Plant_and_Animal_Improvement_Count FY20</a>
        </li>   <li class="list-group-item">
            <a target="_blank" href="https://db.erasmel.abtassociates.com/adminer.php?server=eras-main-instance-1.cuynxtfudbni.us-east-1.rds.amazonaws.com&username=Hamdy_Mansour&db=aggregate&sql=CALL%20Number_new_tech_EG327_Production_Systems_Research_Count(1%2C2020)%3B%0ACALL%20Number_new_tech_EG327_Production_Systems_Research_Count(2%2C2020)%3B%0ACALL%20Number_new_tech_EG327_Production_Systems_Research_Count(3%2C2020)%3B%0ACALL%20Number_new_tech_EG327_Production_Systems_Research_Count(4%2C2020)%3B%0A"> Number_new_tech_EG327_Production_Systems_Research_Count FY20</a>
        </li>   <li class="list-group-item">
            <a target="_blank" href="https://db.erasmel.abtassociates.com/adminer.php?server=eras-main-instance-1.cuynxtfudbni.us-east-1.rds.amazonaws.com&username=Hamdy_Mansour&db=aggregate&sql=CALL%20Number_new_tech_EG327_Social_Science_Research_Count(1%2C2020)%3B%0ACALL%20Number_new_tech_EG327_Social_Science_Research_Count(2%2C2020)%3B%0ACALL%20Number_new_tech_EG327_Social_Science_Research_Count(3%2C2020)%3B%0ACALL%20Number_new_tech_EG327_Social_Science_Research_Count(4%2C2020)%3B%0A"> Number_new_tech_EG327_Social_Science_Research_Count FY20</a>
          </li>
        </ul>


        <b class="mt-3 mb-3 text-uppercase">EG.3.2-27: VALUE OF AGRICULTURE-RELATED FINANCING ACCESSED AS A RESULT OF USG ASSISTANCE</b>
        <ul class="list-group mb-5">
          <li class="list-group-item">
            <a target="_blank" href="https://db.erasmel.abtassociates.com/adminer.php?server=eras-main-instance-1.cuynxtfudbni.us-east-1.rds.amazonaws.com&username=Hamdy_Mansour&db=aggregate&sql=CALL%20Value_ag_financing_EG3227(1%2C%202020)%3B%0ACALL%20Value_ag_financing_EG3227_Composite(1%2C%202020)%3B%0ACALL%20Value_ag_financing_EG3227(2%2C%202020)%3B%0ACALL%20Value_ag_financing_EG3227_Composite(2%2C%202020)%3B%0ACALL%20Value_ag_financing_EG3227(3%2C%202020)%3B%0ACALL%20Value_ag_financing_EG3227_Composite(3%2C%202020)%3B%0ACALL%20Value_ag_financing_EG3227(4%2C%202020)%3B%0ACALL%20Value_ag_financing_EG3227_Composite(4%2C%202020)%3B%0A"> Value_ag_financing_EG3227 FY20</a>

          </li>
        </ul>

        <b class="mt-3 mb-3 text-uppercase">EG.3.1-14: Value of new USG commitments and private-sector investment leveraged by the USG to support food security and nutrition</b>
        <ul class="list-group mb-5">
          <li class="list-group-item">
            <a target="_blank" href="https://db.erasmel.abtassociates.com/adminer.php?server=eras-main-instance-1.cuynxtfudbni.us-east-1.rds.amazonaws.com&username=Hamdy_Mansour&db=aggregate&sql=CALL%20Value_new_USG_private_foodsecurity_nutrition_EG3114(1%2C2020)%3B%0ACALL%20Value_new_USG_private_foodsecurity_nutrition_EG3114_Composite(1%2C2020)%3B%0ACALL%20Value_new_USG_private_foodsecurity_nutrition_EG3114(2%2C2020)%3B%0ACALL%20Value_new_USG_private_foodsecurity_nutrition_EG3114_Composite(2%2C2020)%3B%0ACALL%20Value_new_USG_private_foodsecurity_nutrition_EG3114(3%2C2020)%3B%0ACALL%20Value_new_USG_private_foodsecurity_nutrition_EG3114_Composite(3%2C2020)%3B%0ACALL%20Value_new_USG_private_foodsecurity_nutrition_EG3114(4%2C2020)%3B%0ACALL%20Value_new_USG_private_foodsecurity_nutrition_EG3114_Composite(4%2C2020)%3B%0A">Value_new_USG_private_foodsecurity_nutrition_EG3114 FY20</a>
          </li>
        </ul>

        <b class="mt-3 mb-3 text-uppercase">Youth 3 Percentage of participants who are youth (15-29) in USG-assisted programs designed to increase access to productive economic resources</b>
        <ul class="list-group mb-5">
          <li class="list-group-item">
            <a target="_blank" href="https://db.erasmel.abtassociates.com/adminer.php?server=eras-main-instance-1.cuynxtfudbni.us-east-1.rds.amazonaws.com&username=Hamdy_Mansour&db=aggregate&sql=call%20Percent_youth_participants_USG_YOUTH3(%2710-01-2019%27%2C%2709-31-2020%27%2C1%2C2020)%3B%0Acall%20Percent_youth_participants_USG_YOUTH3_Num_Composite(%2709-01-2019%27%2C%2709-30-2020%27%2C2%2C2020)%3B%0Acall%20Percent_youth_participants_USG_YOUTH3_Den_Composite(%2709-01-2019%27%2C%2709-30-2020%27%2C2%2C2020)%3B%0A">Percent_youth_participants_USG_YOUTH3 FY20</a>
          </li>
                    <li class="list-group-item">
            <a target="_blank" href="https://db.erasmel.abtassociates.com/adminer.php?server=eras-main-instance-1.cuynxtfudbni.us-east-1.rds.amazonaws.com&username=Hamdy_Mansour&db=aggregate&sql=CALL%20Percent_youth_participants_USG_YOUTH3_Num_Composite_Annual()%3B%0ACALL%20Percent_youth_participants_USG_YOUTH3_Den_Annual_Composite()%3B%0A">Percent_youth_participants_USG_YOUTH3 FY20 Composite</a>
          </li>

         <li class="list-group-item">
            <a target="_blank" href="https://db.erasmel.abtassociates.com/adminer.php?server=eras-main-instance-1.cuynxtfudbni.us-east-1.rds.amazonaws.com&username=Hamdy_Mansour&db=aggregate&sql=call%20Percent_youth_participants_USG_YOUTH3(%2710-01-2019%27%2C%2712-31-2019%27%2C1%2C2020)%3B%0ACALL%20Percent_youth_participants_USG_YOUTH3_Composite(%2710-01-2019%27%2C%2712-31-2019%27%2C1%2C2020)%3B%0ACALL%20Percent_youth_participants_USG_YOUTH3_Num_Composite(%2710-01-2019%27%2C%20%2712-31-2019%27%2C1%2C%202020)%3B%0ACALL%20Percent_youth_participants_USG_YOUTH3_Den_Composite(%2710-01-2019%27%2C%20%2712-31-2019%27%2C1%2C%202020)%3B%0A">Percent_youth_participants_USG_YOUTH3 Quarterly FY20</a>
          </li>
        </ul>

      </div>

    </div>
  </div>
</div>
